import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { getMonthShortFromDate, getDayFromDate } from '../../utils/utils';
import { DATE_PURCHASE_START, DATE_PURCHASE_END, TOKEN_SIMBOL, TOKEN_PRICE, TOKEN_TYPE, TOKEN_FUNDRAISING_GOAL, TOKEN_TOTAL_TOKEN } from '../../utils/constants';
import logoMetamask from '../../assets/images/metamask-logo.svg';
import { Web3Context } from '../../web3/context'

import './IcoSale.css';

export function IcoSale({ buyToken }) {
  
  const {quantity, setQuantity} = React.useContext(Web3Context);

  return (
    <div className="container">
      <div className="separator" />
      <div className="justify-content-center d-flex flex-wrap align-items-center">
        <div className="col-12 ico-main-info">
          <div className="col-12 title-h3">
            <FontAwesomeIcon icon={faCalendarAlt} size="2x" className="iconFontAwesome" aria-hidden />
            <span className="icon-h3">Token Sale: {getDayFromDate(DATE_PURCHASE_START)} {getMonthShortFromDate(DATE_PURCHASE_START)} - {getDayFromDate(DATE_PURCHASE_END)} {getMonthShortFromDate(DATE_PURCHASE_END)}</span>
          </div>
          <div className="col-12 col-md-6">
            <ul>
              <li>
                <span className="grey">Ticker: </span>{TOKEN_SIMBOL}
              </li>
              <li>
                <span className="grey">Token type: </span>{TOKEN_TYPE}
              </li>
              <li>
                <span className="grey">Token Price:</span> 1 {TOKEN_SIMBOL} = {TOKEN_PRICE} $
              </li>
              <li>
                <span className="grey">Total Tokens: </span>{TOKEN_TOTAL_TOKEN}
              </li>
              <li>
                <span className="grey">Available for Token Sale:</span> {TOKEN_FUNDRAISING_GOAL} Token
              </li>
            </ul>
          </div>
          <span className="h4">Stages</span>
          <div className="col-12 col-md-6">
            <ul>
              <li>
                <span className="grey">STAGE 1(15th - 19th May) - </span>$0.08
              </li>
              <li>
                <span className="grey">STAGE 2(22th - 26th May) - </span>$0.10
              </li>
              <li>
                <span className="grey">STAGE 3(29th - 26nd June) - </span>$0.12
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 ico-sale-right-col">
          <div className="justify-content-center flex-column">
            <div className="justify-content-center align-items-center metamask-header">
              <img
                src={logoMetamask}
                alt="Metamask logo"
              />
            </div>
          </div>
          <div className="ico-blueline" />
          <div className="token-fund-goal">
            <div className="token-fund-goal-money">OLX token price</div>
            <div className="token-fund-goal-total">0.05 USD</div>
          </div>
          <div className="ico-blueline" />
          <div className="token-fund-goal">
            <div className="token-fund-goal-money">Max OLX you can buy</div>
            <div className="token-fund-goal-total">0 USD</div>
          </div>
          <div className="ico-blueline" />
          <input type={"number"} className="ico-sale-amount" min={"1"} onChange={e => setQuantity(e.target.value)} value={quantity}/>
          <button onClick={buyToken} className='btn token-button buy-btn'>Buy OLX Token</button>
          <div className="ico-blueline" />
          <div className="token-fund-goal">
            <div className="token-fund-goal-money">Total tokens you have purchased</div>
            <div className="token-fund-goal-total">0.0 OLX</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IcoSale;
