import React from "react";
import logoBBCNLaunchpad from "../../assets/images/bbcn-launchpad-logo.png";
import './Header.css';

export function Header() {

  return (
    <div className='bg-linear-gradient'>
      <header className='bit_bcn_header'>
        <nav className='navbar navbar-expand-lg navbar-dark bit_bcn_nav'>
          {/* Container */}
          <div className="container">
            {/* Logo */}
            <a href="https://token.bitbcn.org/#" className="navbar-brand">
              <img src={logoBBCNLaunchpad} alt="BitBCN logo" height={150} width={150} />
            </a>
            {/* Mobile hamburguer menu */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* Navigation */}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-4 mt-4 mt-lg-0 mb-lg-0">
                {/* Perks */}
                <li className="nav-item">
                  <a className="nav-link text-center py-2 py-lg-0 header-nav-link" aria-current="page" href="https://token.bitbcn.org/#perks">Perks</a>
                </li>
                {/* Roadmap */}
                <li className="nav-item">
                  <a className="nav-link text-center py-2 py-lg-0 header-nav-link" aria-current="page" href="https://token.bitbcn.org/#trajectory">Trajectory</a>
                </li>
                {/* Utility */}
                <li className="nav-item">
                  <a className="nav-link text-center py-2 py-lg-0 header-nav-link" aria-current="page" href="https://token.bitbcn.org/#token">Utility Token</a>
                </li>
                {/* Team */}
                <li className="nav-item">
                  <a className="nav-link text-center py-2 py-lg-0 header-nav-link" aria-current="page" href="https://token.bitbcn.org/#team">Team</a>
                </li>
                {/* Stages */}
                <li className="nav-item">
                  <a className="nav-link text-center py-2 py-lg-0 header-nav-link" aria-current="page" href="https://token.bitbcn.org/#ico">Phases</a>
                </li>
                {/* News */}
                <li className="nav-item">
                  <a className="nav-link text-center py-2 py-lg-0 header-nav-link" aria-current="page" href="https://token.bitbcn.org/#news">News</a>
                </li>
              </ul>
              <div className="d-flex justify-content-evenly justify-content-lg-end align-items-center">
                {/* Contact */}
                <button className="btns btn-ghost" type="button">Contact</button>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default 'Header';
