import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faLink } from "@fortawesome/free-solid-svg-icons";


export class IcoLinks extends React.Component {
  render() {
    return (
      <div className="container">
          <div className="separator" />
          <div className="row list">
            <div className="col-12 title-h3">
              <i className="fa fa-link fa-2x" aria-hidden="true" />
              <FontAwesomeIcon icon={faLink} size="2x" className="iconFontAwesome" aria-hidden/>
              <span className="icon-h3">Additional links</span>
            </div>
            <div className="col-12">
              <li>
                -&nbsp;&nbsp;
                <a href="https://olympia.io/es/litepaper" target="_blank" rel="noreferrer">
                  LitePaper
                </a>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faExternalLink} className="iconFontAwesome" aria-hidden/>
              </li>
              <li>
                -&nbsp;&nbsp;
                <a href="https://olympia.io/" target="_blank" rel="noreferrer">
                  Join Whitelist
                </a>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faExternalLink} className="iconFontAwesome" aria-hidden/>
              </li>
            </div>
          </div>
      </div>
    );
  }
}

export default IcoLinks;
