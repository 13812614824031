import { TOKEN_ABI } from '../contracts/BBCNToken';
import { SALE_ABI } from '../contracts/Sale';

export const ABI = {
  SALE_ABI,
  TOKEN_ABI,
}

export const SALE_ADDRESS = process.env.REACT_APP_SALE_ADDRESS //'0xAA5A349e0B1e7D4AC1A17A3DA1e320CBdBe1FAA1' //'0x2A12629A87Cf8b9673b823650E044bc1279c7877';
export const TOKEN_ADDRESS = process.env.REACT_APP_SALE_ADDRESS // '0xa92Ff8Be97d9946C52cb341B8e048e6Ed8f986eD' //'0xaB97b0eEA567Bf8c5eEC65DE489F8faeF13646Af';
export const MAIN_NET_CHAIN_ID = 56;
export const TEST_NET_CHAIN_ID = 97;

export const chainId = MAIN_NET_CHAIN_ID;

//Tiers ranges
export const TIER_1_MIN_NUM_TOKENS = 50000;
export const TIER_1_MAX_NUM_TOKENS = 249999;
export const TIER_2_MIN_NUM_TOKENS = 250000;
export const TIER_2_MAX_NUM_TOKENS = 499999;
export const TIER_3_MIN_NUM_TOKENS = 500000;
export const TIER_3_MAX_NUM_TOKENS = 999999;
export const TIER_4_MIN_NUM_TOKENS = 1000000;


export const DATE_PURCHASE_START = new Date('2022-05-15T00:00:00');
export const DATE_PURCHASE_END = new Date('2022-06-02T23:59:59');
export const TOKEN_SIMBOL = "OLX";
export const TOKEN_PRICE = "0.08";
export const TOKEN_ACCEPTS = "BNB";
export const TOKEN_TYPE = "Polygon-Matic ERC-20";
export const TOKEN_FUNDRAISING_GOAL = "100,000,000";
export const TOKEN_TOTAL_TOKEN = "(6%) 60,000,000,000";