import React from "react";
import logoLinkedIn from '../../assets/images/icon-linkedin.svg';
import logoInstagram from '../../assets/images/icon-instagram.svg';
import logoTwitter from '../../assets/images/icon-twitter.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

import './Social.css';

export class Social extends React.Component {
  render() {
    return (
      <div className="bit_bcn_social">
        <div className="container">
            <div className="row social-row">
            <div className="col-12 col-lg-8 d-flex flex-column flex-md-row mb-5 mb-lg-0 justify-content-center align-items-center">
                <div className="social-wrapper mb-5 mb-md-0">
                <a href="https://es.linkedin.com/company/belobaba" target="_blank" rel="noreferrer" className="social-name">
                    <img className="social-img" src={logoLinkedIn} alt="LinkedIn logo"/>
                </a>
                <a href="https://es.linkedin.com/company/belobaba" target="_blank" rel="noreferrer" className="social-name">
                    Linkedin
                </a>
                </div>
                <div className="social-wrapper mb-5 mb-md-0">
                <a href="https://www.instagram.com/blockbitbcn/" target="_blank" rel="noreferrer" className="social-name">
                <img className="social-img" src={logoInstagram} alt="Instagram logo" />
                </a>
                <a href="https://www.instagram.com/blockbitbcn/" target="_blank" rel="noreferrer" className="social-name">
                    Instagram
                </a>
                </div>
                <div className="social-wrapper mb-5 mb-md-0">
                <a href="https://www.twitter.com/bitbcn" target="_blank" rel="noreferrer" className="social-name">
                    <img className="social-img" src={logoTwitter} alt="Twitter logo"/>
                </a>
                <a href="https://www.twitter.com/bitbcn" target="_blank" rel="noreferrer"className="social-name">
                    TWITTER
                </a>
                </div>
            </div>
            <div className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">
                <p className="follow-us">
                    <FontAwesomeIcon icon={faTelegram} size="2x" className="iconFontAwesome ico-telegram" aria-hidden/>
                    <a href="https://t.me/bitbcnlaunchpad" target="_blank" rel="noreferrer">Follow us on Telegram</a>
                </p>
                <p className="community">AN ACTIVE AND DINAMIC COMMUNITY</p>
            </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Social;
