import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter ,faLinkedinIn, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { getTokenStateAndDays } from '../../utils/utils';
import logoOlympia from '../../assets/images/olympia-logo.jpg';
import cabeceraOlympia from '../../assets/images/imagen-cabecera-1024x575.jpg';
import {DATE_PURCHASE_START, DATE_PURCHASE_END} from '../../utils/constants';


import './IcoDetails.css';


export function IcoDetails() {

  const {days, state} = getTokenStateAndDays(DATE_PURCHASE_START, DATE_PURCHASE_END);
  
    return (
      <div className="container">
        <div className="col-12 ico-main-info">
                <div>
                  <img
                    className="ico-logo"
                    src={logoOlympia}
                    alt="Olympia"
                    loading="lazy"
                  />
                  <span className="h1 ico-main-info-title">Olympia</span>
                </div>
            </div>
          <div className="ico-row d-flex flex-wrap align-items-center justify-content-center" id="middle-desk">
            <div className="col-12 ico-media">
              <div className="embed-container">
                <img src={cabeceraOlympia} alt="Olympia" loading="lazy"/>
              </div>
            </div>
            <div className="col-12 ico-right-col">
              <div className="token-sale-ends">Token Sale {state}</div>
              <div className="h3 token-sale-ends-days">
                  {state === 'ended' ? DATE_PURCHASE_END.toLocaleDateString('en-US') : `${days} days` }
              </div>
              <div className="ico-blueline" />
              <div className="token-fund-goal">
                <div className="token-fund-goal-money">Hardcap</div>
                <div className="token-fund-goal-total">
                  $5.000.000
                </div>
              </div>
              <div className="ico-blueline" />
              <a
                href="https://olympia.io/"
                target="_blank"
                rel="noreferrer"
                className="btn token-button btn-blue"
              >
                Website
              </a>
              <a
                href="https://whitepaper.olympia.io/"
                className="btn token-button btn-blue"
                target="_blank"
                rel="noreferrer"
              >
                WhitePaper
              </a>
              <div className="ico-blueline" />
              <div className="social-links-title">social links</div>
              <div className="social-links">
              <a href="https://t.me/olympia_world" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTelegram} fixedWidth aria-hidden/>
                </a>
                <a href="https://www.linkedin.com/company/olympiaworld/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} fixedWidth aria-hidden/>
                </a>
                <a href="https://twitter.com/olympia_world/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} fixedWidth aria-hidden/>
                </a>
              </div>
            </div>
          </div>
      </div>
    );
}
