import React from 'react'
import { Web3Context } from '../../web3/context'
import './Connect.css'

export function Connect({ connectWallet }) {
  const { account, setAccount, myTokens, setMyTokens, totalTokens, setTotalTokens, tier, setTier } = React.useContext(Web3Context);


  const renderTokensBoughtItem = (token, index) => (
    <li key={index}><a className="dropdown-item" href="/#">Locked: {token.locked}, quantity: {token.quantity}</a></li>
  )

  const renderTotalTokensBought = () => (
    <li><a className="dropdown-item" href="/#">Total Tokens Purchase: {totalTokens}</a></li>
  )

  const renderTier = () => (
    <li><a className="dropdown-item" href="/#">Your are in Tier: {tier}</a></li>
  )

  const disconnect = () => {
    setAccount(undefined);
    setMyTokens(undefined);
    setTotalTokens(0);
    setTier(0);
  }

  return (
    <>
      <div className='bit_bcn_connect container d-flex justify-content-end flex-column flex-lg-row pt-3'>
        {!!account && !!myTokens && myTokens?.length > 0 && (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              Tokens purchased
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {myTokens.map(renderTokensBoughtItem)}
              {renderTotalTokensBought()}
              {renderTier()}
            </ul>
          </div>
        )}
        {account && (
          <>
            <button className='btns btn-ghost' style={{ pointerEvents: 'none', border: 'none' }}>{account}</button>
            <button onClick={disconnect} className='btns btn-ghost'>Disconnect</button>
          </>
        )}
        {!account && <button onClick={connectWallet} className='btns btn-ghost'>Connect to BSC</button>}
      </div>
    </>
  )
}

export default Connect;
