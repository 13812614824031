import React from 'react';
import Web3 from 'web3';

import * as SOURCES from '../utils/constants';

const web3 = window.web3 ? new Web3(window.web3.currentProvider) : undefined;
const saleContract = window.web3
  ? new web3.eth.Contract(SOURCES.ABI.SALE_ABI, SOURCES.SALE_ADDRESS)
  : {};

const tokenContract = window.web3
  ? new web3.eth.Contract(SOURCES.ABI.TOKEN_ABI, SOURCES.TOKEN_ADDRESS)
  : {};

export const Web3Context = React.createContext({});

export const WebThreeProvider = ({ children }) => {
  const [webThree, setWebThree] = React.useState({
    web3,
    saleContract,
    tokenContract,
  });
  const [account, setAccount] = React.useState(undefined);
  const [contract, setContract] = React.useState(undefined);
  const [myTokens, setMyTokens] = React.useState(undefined);
  const [totalTokens, setTotalTokens] = React.useState(0);
  const [tier, setTier] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  
  return (
    <Web3Context.Provider value={{ webThree, setWebThree, account, setAccount, contract, setContract, myTokens, setMyTokens, totalTokens, setTotalTokens, tier, setTier, quantity, setQuantity}}>
      {children}
    </Web3Context.Provider>
  );
};
