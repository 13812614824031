import React from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import Swal from "sweetalert2";

import { Web3Context } from "./web3/context";
import { chainId } from "./utils/constants";
import { getNetworkData } from "./utils/utils";

import { 
  TIER_1_MIN_NUM_TOKENS, 
  TIER_1_MAX_NUM_TOKENS, 
  TIER_2_MAX_NUM_TOKENS, 
  TIER_3_MAX_NUM_TOKENS, 
  TIER_4_MIN_NUM_TOKENS 
 } from './utils/constants';

import { Background } from "./components/Background/Background";
import { Header } from "./components/Header/Header";
import { IcoDetails } from "./components/IcoDetails/IcoDetails";
import { IcoSale } from "./components/IcoSale/IcoSale";
import { IcoReview } from "./components/IcoReview/IcoReview";
import { IcoLinks } from "./components/IcoLinks/IcoLinks";
import { Footer } from "./components/Footer/Footer";
import { Social } from "./components/Social/Social";
import { Connect } from "./components/Connect/Connect";

function App() {

  const [isMetamaskInstalled, setIsMetamaskInstalled] = React.useState(false);
  const {
    webThree: { web3, saleContract },
    account,
    setAccount,
    contract,
    setContract,
    setMyTokens,
    totalTokens,
    setTotalTokens,
    setTier,
    quantity,
    setQuantity
  } = React.useContext(Web3Context);

  const ethereum = window.ethereum;

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (account) {
      getBoughtTokens();
    }
  }, [account]);

  React.useEffect(() => {
    if (isMetamaskInstalled && ethereum) {
      // Cuenta Cambiada
      ethereum.on("accountsChanged", function (accounts) {
        setAccount(accounts[0]);
        window.location.reload();
      });

      // Red BlockChain Cambiada
      ethereum.on("chainChanged", async () => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        window.location.reload();
      });
    }
  }, [isMetamaskInstalled]);

  const init = async () => {
    if (web3) {
      getData();
      web3.eth.getAccounts(function (err, accounts) {
        if (err != null) {
          console.error("An error occurred: " + err);
        } else if (accounts.length === 0) {
          console.log("User is not logged in to MetaMask");
        } else {
          setAccount(accounts[0]);
        }
      });
    }

    const provider = await detectEthereumProvider();
    if (provider) {
      // From now on, this should always be true:
      // provider === window.ethereum
      setIsMetamaskInstalled(true);
      if (!ethereum.isConnected()) {
        console.log("Please, connect your wallet");
      } else {
        console.log("Wallet Connected");
      }
    } else {
      console.log("Please install MetaMask!");
      setIsMetamaskInstalled(false);
    }

    if (provider) {
      let currentChainId = await web3.eth.getChainId();
      console.log("CHAIN ID " + currentChainId);

      if (currentChainId !== chainId) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please, connect to the Binance Smart Chain",
        }).then(() => {
          return changeChain();
        });
      }
    }
  };

  const changeChain = async () => {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: getNetworkData(chainId),
    });
  };

  async function connectWallet() {
    if (isMetamaskInstalled) {
      const accounts = await web3.eth.requestAccounts();
      setAccount(accounts[0]);

      console.log(accounts);

      let currentChainId = await web3.eth.getChainId();
      console.log(currentChainId);

      if (currentChainId !== chainId) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please, connect to the Binance Smart Chain",
        }).then(() => {
          changeChain();
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please install metamask",
      });
    }
  }

  const getData = async () => {
    try {
      const currentPhase = await saleContract.methods.getcurrentPhase().call();
      const latestBNBPrice = await saleContract.methods.getLatestPrice().call();
      const ownerAddress = await saleContract.methods.dispatcher().call();
      const currentPhasePrice = currentPhase.price;
      const tokenPrice = currentPhase.price / latestBNBPrice;
      const minimunEntryDecimal = web3.utils.fromWei(
        currentPhase.minimunEntry,
        "ether"
      );

      setContract({
        currentPhase,
        latestBNBPrice,
        currentPhasePrice,
        tokenPrice,
        minimunEntryDecimal,
        ownerAddress,
      });
    } catch (e) {
      console.log(e);
    }
  };

  
  const getBoughtTokens = async () => {
    try {
      const idArray = await saleContract.methods.getIDs(account).call();
      if (idArray && idArray.length > 0) {
        Promise.all(idArray.map((id) => saleContract.methods.showMyShops(id, account).call()))
          .then((results) => {
            if (results && results.length > 0) {
              setMyTokens(results.map((tkn) => ({
                locked: new Date(tkn[0] * 1000).toLocaleDateString(),
                quantity: web3.utils.fromWei(tkn[1], "ether"),
              })));
              
              const totalPurchase = results.reduce((total, tkn) =>  total = total + Number(web3.utils.fromWei(tkn[1], "ether")),0)
              
              setTotalTokens(totalPurchase);
              getTier();
            }
          })
          .catch((err) => console.log(err))
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTier = async () => {
    try{

        switch(true){
          case (totalTokens < TIER_1_MIN_NUM_TOKENS):
              setTier(0);
              break;
          case (totalTokens <= TIER_1_MAX_NUM_TOKENS):
              setTier(1);
              break;
          case (totalTokens <= TIER_2_MAX_NUM_TOKENS):
              setTier(2);
              break;
          case (totalTokens <= TIER_3_MAX_NUM_TOKENS):
              setTier(3);
              break;
          case (totalTokens >= TIER_4_MIN_NUM_TOKENS):
              setTier(4);
              break;
           default:
              setTier(0);
        }  
    }
    catch (e) {
      console.log(e);
    }
  }

  const buyToken = async() => {
    console.log('quantity: ' + quantity);
    
    if (!account || !contract) {
      Swal.fire({
        icon: "error",
        title: "Please connect to Metamask!",
      });
      setQuantity(0);
      return;
    }
    
    if (!quantity || Number(quantity) <= 0) {
  
      Swal.fire({
        icon: "error",
        title: "The number of tokens to purchase must be greater than 0",
      });
      setQuantity(0);
      return;
    }
  
  }
  
  
  /*
  const buyToken = () => {
    if (!account || !contract) {
      Swal.fire({
        icon: "error",
        title: "Please connect to Metamask!",
      });
    } else {
      Swal.fire({
        title: "Quantity",
        input: "number",
        inputLabel: "Quantity of tokens",
        showConfirmButton: true,
        inputValidator: (value) => {
          if (!value || value < parseInt(contract.minimunEntryDecimal)) {
            return `Minimum entry quantity is ${parseInt(
              contract.minimunEntryDecimal
            )}`;
          }
        },
      }).then(async ({ value: quantity }) => {

        if (!quantity || quantity < parseInt(contract.minimunEntryDecimal)) {
          return;
        }

        const currentPhase = await saleContract.methods
          .getcurrentPhase()
          .call();
        const latestBNBPrice = await saleContract.methods
          .getLatestPrice()
          .call();
        const tokenPrice = currentPhase.price / latestBNBPrice;

        await saleContract.methods
          .buyToken(web3.utils.toWei(quantity))
          .send({
            from: account,
            value: Math.ceil(tokenPrice * web3.utils.toWei(quantity)),
          })
          .then((receipt) => {
            console.log(receipt);
            
            if (referral) {
              saveReferredTx(quantity, referral);
            }

            Swal.fire({
              icon: "success",
              title: "Successful purchase",
            }).then(() => {
              getBoughtTokens()
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: 'Error',
            });
          });
      });
    }
  };
  */

  return (
    <React.Fragment>
      <Background>
        <Connect connectWallet={connectWallet} />
        <Header />
      </Background>
      <IcoDetails />
      <IcoSale buyToken={buyToken}/>
      <IcoReview />
      <IcoLinks />
      <Social />
      <Footer />
    </React.Fragment>
  );
}

export default App;
