import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import './IcoReview.css';

export class IcoReview extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="separator" />
        <div className="row list">
          <div className="col-12 title-h3">
            <FontAwesomeIcon icon={faBullseye} size="2x" className="iconFontAwesome" aria-hidden/>
            <span className="icon-h3">Short Review</span>
          </div>
          <div className="col-12">
            <li>
              <span className="grey">Description: </span>
              <span className="ico-review-description">We are on a mission to make the world more physically active, healty and playful. 
              To achieve our goals, we have created the Currency of Sports (OLX) that rewards people for ther activity. 
              We channel our community through the OLYMPIA Superapp where services
              (miniapps) can be activated making sports more fun and accessible.</span>
            </li>
            <li>
              <span className="grey">Role of Token: </span>
              <span className="ico-review-description">Utility (move-to-earn)</span>
            </li>
          </div>
        </div>
      </div>
    );
  }
}

export default IcoReview;