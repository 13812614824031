import { MAIN_NET_CHAIN_ID, TEST_NET_CHAIN_ID } from './constants';

export function getTokenStateAndDays(dateInit, dateEnd) {
    
    let today = new Date();
    let gap = null;
    let days = 0;
    let state = "";
    
    if (today <= dateInit ) {
        gap = dateInit - today;
        days = Math.ceil(gap / (1000 * 3600 * 24));
        state = 'starts in';
    }

    if (dateEnd){
        if (dateInit < today && today <= dateEnd) {
            gap = dateEnd - today;
            days = Math.ceil(gap / (1000 * 3600 * 24));
            state = 'ends in';
        }   
        else if(today > dateEnd)
        {
            gap = today - dateEnd;
            days = Math.ceil(gap / (1000 * 3600 * 24));
            state = 'ended';

        }
    }

    return {days, state};
}

export function getMonthShortFromDate(date){
    return date.toLocaleString('en-us', { month: 'short' });
}

export function getDayFromDate(date){
    return date.getDate();
}

export const getNetworkData = (id) => {
    switch (id) {
      //bscmainet
      case MAIN_NET_CHAIN_ID:
        return [
          {
            chainId: "0x38",
            chainName: "BSCMAINET",
            rpcUrls: ["https://bsc-dataseed1.binance.org"],
            nativeCurrency: {
              name: "BINANCE COIN",
              symbol: "BNB",
              decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.bscscan.com/"],
          },
        ];
      //bsctestnet
      case TEST_NET_CHAIN_ID:
      default:
        return [
          {
            chainId: "0x61",
            chainName: "BSCTESTNET",
            rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
            nativeCurrency: {
              name: "BINANCE COIN",
              symbol: "BNB",
              decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.bscscan.com/"],
          },
        ];
    }
  }

