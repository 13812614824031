import React from 'react';
import logoBBCNlaunchpad from '../../assets/images/bbcn-launchpad-logo.png';
import './Footer.css';
import ctdSec from '../../assets/images/ctd-sec.png';
import belobabaLogo from '../../assets/images/belobaba-logo.png';

export function Footer() {

  return (
    <footer className='bit_bcn_footer'>
      <div className='container'>
        <div className='row footer-content-wrapper'>
          <div className='col-12 col-lg-6 d-flex flex-md-row flex-column justify-content-center align-items-start'>
            <img className='footer-logo' src={logoBBCNlaunchpad} alt="BitBCN logo" height={150} width={150} />
            <div className='footer-title'>
              <h4 className='private-sale'>Private Sale, <br /> The Launchpad</h4>
              <p className='private-sale-caption'>PRIVATE SALE 2022</p>
              <p className='private-sale-caption'>SINCE 2017</p>
            </div>
          </div>
          <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
            {/* BUY CTA */}
            <div style={{ marginBottom: 26 }} className='d-flex flex-column flex-md-row'>
              <a href='https://token.bitbcn.org/' rel='noreferrer' target='_blank' className='btn btn-orange me-0 me-md-3 mb-3 mb-md-0'>Buy Token BITBCN</a>
              <button onClick={() => { window.open('https://token.bitbcn.org/Whitepaper-EN-170122.pdf') }} className='btns btn-ghost'>Download WhitePaper</button>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-md-row align-items-center flex-wrap justify-content-center footer-links-wrapper'>
          <a className='ctd-sec' target="_blank" rel="noreferrer" href="https://token.bitbcn.org/Cybersecurity_Audit_CTDSEC_BitBCN.pdf" >
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src={ctdSec} height={100} width={100} alt="Logo Subutai" />
              <p>CTD SEC - You are protected</p>
            </div>
          </a>
          <a href='https://www.belobabafund.com/' rel='noreferrer' target='_blank'><img src={belobabaLogo} height={100} alt="Logo Belobaba" /></a>
          <a href='https://token.bitbcn.org/' rel='noreferrer' target='_blank'><img src={logoBBCNlaunchpad} height={100} width={100} alt="Logo BITBCN" /></a>
          <a target="_blank" href="https://token.bitbcn.org/legal" rel="noreferrer" className='footer-link me-md-5 mb-md-0 mb-5'>LEGAL TERMS</a>
          <a target="_blank" href="https://token.bitbcn.org/privacy" rel="noreferrer" className='footer-link me-md-5 mb-md-0 mb-5'>PRIVACY</a>
        </div>
      </div>
    </footer>
  );
}